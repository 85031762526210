<template>
  <v-container class="general">
    <PageTitle :title="'Group policies'">
      <template slot="actions">
        <v-text-field
          v-model="searchInput"
          light
          solo
          class="search-input body-1"
          label="Type to filter policies"
          hide-details
        >
          <template slot="append">
            <font-awesome-icon
              :icon="['fas', 'search']"
              :style="{ fontSize: '20px' }"
              class="ml-1 theme-accent"
            ></font-awesome-icon>
          </template>
        </v-text-field>
      </template>
    </PageTitle>
    <v-layout class="row wrap">
      <v-flex class="xs12">
        <div v-if="!loading && !filtredPolicies.length">
          Nothing found
        </div>
        <v-card
          v-if="filtredPolicies.length"
          class="px-4 pt-3 pb-2 border-radius"
        >
          <v-layout class="row wrap align-center">
            <v-flex
              v-for="(policy, i) in filtredPolicies"
              :key="`policy-${i}`"
              class="xs12 mb-2"
              ><v-divider v-if="i !== 0" class="mb-2"></v-divider
              ><a @click.stop="editGroupPolicy(policy.id)">{{
                policy.description
              }}</a>
              <div class="caption">
                <span class="grey--text">Assigned roles:</span>
                {{ formatAssignedRoles(policy.group_roles) }}
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="totalPages > 1" class="xs12 text-xs-center pt-4">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listPolicies"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    searchInput: "",
    totalPages: 0,
    totalVisible: 5,
    perPage: 1000,
    page: 1,
    policies: [],
  }),
  computed: {
    filtredPolicies() {
      return this.policies.filter((el) =>
        el.description.toUpperCase().includes(this.searchInput.toUpperCase()) || el.name.toUpperCase().includes(this.searchInput.toUpperCase()),
      );
    },
  },
  mounted() {
    this.listGroupPolicies();
  },
  methods: {
    listGroupPolicies() {
      const params = { per_page: this.perPage, page: this.page };

      this.loading = true;

      this.$api.groupPolicies.list(params).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.policies = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
        },
        () => {
          this.loading = false;
        },
      );
    },
    formatAssignedRoles(roles) {
      if (!roles) return "None";
      if (!roles.length) return "None";
      const string = roles.map((el) => el.description).toString();

      return string.replace(/,/g, ", ");
    },
    editGroupPolicy(id) {
      if (!id) return;

      this.$router
        .push({
          name: "group_policies_edit",
          params: { policy_id: id },
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
